import React,{ useRef,useState,useEffect} from "react";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Carousel from 'react-elastic-carousel';
import LoadingImg from  '../img/loading.gif';
import Aos from 'aos';

function TeamComp() {

    const   our_team_items = [{ id: 0, end: 1 }, { id: 2, end: 3 }, { id: 4, end: 5 }];

    const items = [{ id: 0, end: 1 }, { id: 2, end: 3 }];

    useEffect(() => {
        Aos.init({duration: 6000});
        
      }, []);


    return <div data-aos="fade-right" id="team" className="section">
        <div className="row our-works-row">
            <div className="col-md-4">
                <h5>
                    <strong className="section-title">
                        Team
                    </strong>
                </h5>
            </div>
        </div>
        <Tabs className="our-team-nav-tabs">
            <Tab label="Our Team" >
                <Carousel itemsToShow={1} >
                    {our_team_items.map(item => <Team key={item.id} id={item.id} end={item.end} ></Team>)}
                </Carousel>
            </Tab>
            <Tab label="Workshop">
                <Carousel itemsToShow={1} >
                    {items.map(item => <Workshop key={item.id} id={item.id} end={item.end} ></Workshop>)}
                </Carousel>
            </Tab>
        </Tabs>
    </div>;
}

function Team(props) {
    const innerCounter =useRef(0);

    const [teamState,setbordState] = useState(
        {
            loading: false
    });
    const LoadingFun = () => { 

        setbordState({

            loading:true
        });
    
    };
    const innerImageLoaded = () => {
           
        innerCounter.current += 1;
        if (innerCounter.current >= 8 ) {
            LoadingFun();
        }
      
    }
    const our_team1 = [];
    let counter = [{ start: 1, end: 5 }, { start: 5, end: 9 }, { start: 9, end: 13 }, { start: 13, end: 17 }, { start: 17, end: 21 }, { start: 21, end: 25 }];
    for (var i = counter[props.id].start; i < counter[props.id].end; i++) {
        our_team1.push({ id: i });
    }
    const our_team2 = [];
    for (var i = counter[props.end].start; i < counter[props.end].end; i++) {
        our_team2.push({ id: i });
    }
    return <div>
                <div id="LoadingImg" className="row no-gutters" style={{display: !teamState.loading ? "block" : "none"}}>
                            <img src={LoadingImg} style={{padding: '8rem'}}/>
                </div>
                <div style={{display: teamState.loading ? "block" : "none"}}>
                    <div className="row">
                        {our_team1.map(item => <TeamCompImage key={item.id} src={'../img/our_team/our_team_' + item.id + '.jpeg'} innerImageLoaded={innerImageLoaded} ></TeamCompImage>)}
                    </div>
                    <div className="row">
                        {our_team2.map(item => <TeamCompImage key={item.id} src={'../img/our_team/our_team_' + item.id + '.jpeg'} innerImageLoaded={innerImageLoaded} ></TeamCompImage>)}
                    </div>
                </div>
        
    </div>;
}

function Workshop(props) {
    const innerCounter =useRef(0);

    const [teamState,setbordState] = useState(
        {
            loading: false
    });
    const LoadingFun = () => { 

        setbordState({

            loading:true
        });
    
    };
    const innerImageLoaded = () => {
           
        innerCounter.current += 1;
        if (innerCounter.current >= 8 ) {
            LoadingFun();
        }
      
    }
    const workshop1 = [];
    let counter = [{ start: 1, end: 5 }, { start: 5, end: 9 }, { start: 9, end: 13 }, { start: 13, end: 17 }];
    for (var i = counter[props.id].start; i < counter[props.id].end; i++) {
        workshop1.push({ id: i });
    }
    const workshop2 = [];
    for (var i = counter[props.end].start; i < counter[props.end].end; i++) {
        workshop2.push({ id: i });
    }
    return <div>
                <div id="LoadingImg" className="row no-gutters" style={{display: !teamState.loading ? "block" : "none"}}>
                                <img src={LoadingImg} style={{padding: '8rem'}}/>
                </div>
                <div style={{display: teamState.loading ? "block" : "none"}}>
                    <div className="row">
                        {workshop1.map(item => <TeamCompImage key={item.id} src={'../img/workshop/workshop_' + item.id + '.jpeg'} innerImageLoaded={innerImageLoaded}></TeamCompImage>)}
                    </div>
                    <div className="row">
                        {workshop2.map(item => <TeamCompImage key={item.id} src={'../img/workshop/workshop_' + item.id + '.jpeg'} innerImageLoaded={innerImageLoaded}></TeamCompImage>)}
                    </div>
                </div>
            </div>

}

function TeamCompImage(props) {
    const innerImageLoaded=props.innerImageLoaded;
    
    return <div className="col-md-3">
        <img src={props.src} onLoad={() => innerImageLoaded()} className="team-comp-image">
        </img>
    </div>
}

export default TeamComp;