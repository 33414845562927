import React, { Component } from 'react';
import {storyImg} from '../data';

const InfoContext =React.createContext();

class InfoProvider extends Component{

    state={
        storyImg:storyImg,

    };
    getItem = id =>{
        const item = this.state.placeInfo.find(item=>item.id===id);
        return item;
    }

    
   
    render(){
        return (
            <InfoContext.Provider value={{
                storyImg:this.state.storyImg,
             
            }}>
                {this.props.children}
            </InfoContext.Provider>

           
        );
    }

}

const InfoConsumer =InfoContext.Consumer;

export  {InfoProvider,InfoConsumer};

