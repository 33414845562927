import imgOne1 from '../src/img/13/037_test.jpg';
import imgOne2 from "../src/img/13/029_test.jpg";
import imgOne3 from "../src/img/13/031_test.jpg";
import imgOne4 from "../src/img/13/v0-4.jpg";
import gifData1 from '../src/img/13/gifOne.gif';
import imgHover1 from '../src/img/13/StoryboardHover-1.png';

import imgTwo1 from '../src/img/15/029_test.jpg';
import imgTwo2 from "../src/img/15/025_test.jpg";
import imgTwo3 from "../src/img/15/007_test.jpg";
import imgTwo4 from "../src/img/15/v0-3.jpg";
import gifData2 from '../src/img/15/towGif.gif';
import imgHover2 from '../src/img/15/StoryboardHover-2.png';

import imgThree1 from '../src/img/14/028_test.jpg';
import imgThree2 from "../src/img/14/026_test.jpg";
import imgThree3 from "../src/img/14/024_test.jpg";
import imgThree4 from "../src/img/14/021_test.jpg";
import gifData3 from '../src/img/14/gifThree.gif';
import imgHover3 from '../src/img/14/StoryboardHover-3.png';

import imgFour1 from '../src/img/four/007_test.jpg';
import imgFour2 from '../src/img/four/001_test.jpg';
import imgFour3 from '../src/img/four/003_test.jpg';
import imgFour4 from '../src/img/four/004_test.jpg';
import imgFour5 from '../src/img/four/v0-2.jpg';

import imgHover4 from '../src/img/four/StoryboardHover-4.png';

import imgFive1 from '../src/img/five/010_test.jpg';
import imgFive2 from '../src/img/five/007_test.jpg';
import imgFive3 from '../src/img/five/008_test.jpg';
import imgFive4 from '../src/img/five/009_test.jpg';
import imgFive5 from '../src/img/five/v0-2.jpg';

import imgHover5 from '../src/img/five/StoryboardHover-5.png';

import imgSix1 from '../src/img/six/014_test.jpg';
import imgSix2 from '../src/img/six/015_test.jpg';
import imgSix3 from '../src/img/six/017_test.jpg';
import imgSix4 from '../src/img/six/v0-2.jpg';

import imgHover6 from '../src/img/six/StoryboardHover-6.png';

import imgSeven1 from '../src/img/7/001_TEST.jpg';
import imgSeven2 from "../src/img/7/002_TEST.jpg";
import imgSeven3 from "../src/img/7/003_TEST.jpg";
import imgSeven4 from "../src/img/7/004_TEST.jpg";
import gifData7 from '../src/img/7/7.gif';
import imgHover7 from '../src/img/7/StoryboardHover-7.png';

import imgeight1 from '../src/img/8/00.png';
import imgeight2 from "../src/img/8/005_TEST.jpg";
import imgeight3 from "../src/img/8/006_TEST.jpg";
import imgeight4 from "../src/img/8/007_TEST(2).jpg";
import gifData8 from '../src/img/8/8.gif';
import imgHover8 from '../src/img/8/StoryboardHover-8.png';

import imgNine1 from '../src/img/9/007_TEST.jpg';
import imgNine2 from "../src/img/9/008_TEST.jpg";
import imgNine3 from "../src/img/9/009_TEST.jpg";
import imgNine4 from "../src/img/9/010_TEST.jpg";
import gifData9 from '../src/img/9/9.gif';
import imgHover9 from '../src/img/9/StoryboardHover-9.png';

import imgTen1 from '../src/img/10/001_test.jpg';
import imgTen2 from '../src/img/10/002_test.jpg';
import imgTen3 from '../src/img/10/003_test.jpg';
import imgTen4 from '../src/img/10/004_test.jpg';
import imgHover10 from '../src/img/10/StoryboardHover-10.png';

import imgEleven1 from '../src/img/11/004_test.jpg';
import imgEleven2 from '../src/img/11/005_test.jpg';
import imgEleven3 from '../src/img/11/006_test.jpg';
import imgEleven4 from '../src/img/11/007_test.jpg';
import imgHove11 from '../src/img/11/StoryboardHover-11.png';

import imgTwelve1 from '../src/img/12/014_test.jpg';
import imgTwelve2 from '../src/img/12/015_test.jpg';
import imgTwelve3 from '../src/img/12/016_test.jpg';
import imgTwelve4 from '../src/img/12/017_test.jpg';
import imgTwelve5 from '../src/img/12/018_test.jpg';
import imgHover12 from '../src/img/12/StoryboardHover-12.png';

import imgThirteen1 from '../src/img/one/001_test.jpg';
import imgThirteen2 from '../src/img/one/001_test.jpg';
import imgThirteen3 from '../src/img/one/001_test.jpg';
import imgThirteen4 from '../src/img/one/001_test.jpg';
import imgThirteen5 from '../src/img/one/001_test.jpg';

import imgFourteen1 from '../src/img/tow/001_test.jpg';
import imgFourteen2 from '../src/img/tow/001_test.jpg';
import imgFourteen3 from '../src/img/tow/001_test.jpg';
import imgFourteen4 from '../src/img/tow/001_test.jpg';
import imgFourteen5 from '../src/img/tow/001_test.jpg';

import imgFiveteen1 from '../src/img/three/001_test.jpg';
import imgFiveteen2 from '../src/img/three/001_test.jpg';
import imgFiveteen3 from '../src/img/three/001_test.jpg';
import imgFiveteen4 from '../src/img/three/001_test.jpg';
import imgFiveteen5 from '../src/img/three/001_test.jpg';

export const storyImg = [

  [
    [
      13,
      [
        imgThirteen1,
        imgThirteen1,
        imgThirteen1,
        imgThirteen1,
      ],
      '',
      '',
      imgHover10,
    ],
    [
      14,
      [
        imgFourteen2,
        imgFourteen2,
        imgFourteen2,
        imgFourteen2,
      ],
      '',
      '',
      imgHove11,
    ],
    [
      15,
      [
        imgFiveteen1,
        imgFiveteen1,
        imgFiveteen1,
        imgFiveteen1,
      ],
      imgFiveteen1,
      '',
      imgFiveteen1,
    ],
  ]
  ,

  [
    [
      4,
      [
        imgFour1,
        imgFour2,
        imgFour3,
        imgFour4,
      ],
      imgFour5,
      '',
      imgHover4,
    ],
    [
      5,
      [
        imgFive1,
        imgFive2,
        imgFive3,
        imgFive4,
      ],
      imgFive5,
      '',
      imgHover5,
    ],
    [
      6,
      [
        imgSix1,
        imgSix2,
        imgSix3,
        imgSix4,
      ],
      '',
      '',
      imgHover6,
    ]
  ]
  ,
  [
    [
      7,
      [
        gifData7,
        imgSeven1,
        imgSeven2,
        imgSeven3,
        imgSeven4,
      ],
      '',
      imgHover7,
    ],
    [
      8,
      [
        gifData8,
        imgeight1,
        imgeight2,
        imgeight3,
        imgeight4,
      ],
      '',
      imgHover8,
    ],
    [
      9,
      [
        gifData9,
        imgNine1,
        imgNine2,
        imgNine3,
        imgNine4,
      ],
      '',
      imgHover9,
    ]
  ]
  ,
  [
    [
      10,
      [
        imgTen1,
        imgTen2,
        imgTen3,
        imgTen4,
      ],
      '',
      '',
      imgHover10,
    ],
    [
      11,
      [
        imgEleven1,
        imgEleven2,
        imgEleven3,
        imgEleven4,
      ],
      '',
      '',
      imgHove11,
    ],
    [
      12,
      [
        imgTwelve1,
        imgTwelve2,
        imgTwelve3,
        imgTwelve4,
      ],
      imgTwelve5,
      '',
      imgHover12,
    ],
  ]
  ,
  [
    [
      1,
      [
        gifData1,
        imgOne1,
        imgOne2,
        imgOne3,
        imgOne4,
      ],
    
      '',
      imgHover1,
    ]
    ,
    [
      2,
      [
        gifData2,
        imgTwo1,
        imgTwo2,
        imgTwo3,
        imgTwo4,
      ],
      '',
      imgHover2,
    
    ],
    
    [
      3,
      [
        gifData3,
        imgThree1,
        imgThree2,
        imgThree3,
        imgThree4,
      ],
      '',
      imgHover3,
    ]
  ]
];
export const backGroundImg = [
  imgHover1,
  imgHover2,
  imgHover3,
  imgHover7,
  imgHover8,
  imgHover9

];
