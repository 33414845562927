import React, { useRef } from "react";
import { TweenLite } from "gsap";
import ThirdSliderimg from '../img/mainSlider.jpg';
import SecandSliderimg from '../img/secondSlider2.jpg';
import MainSliderimg from '../img/mainSlider3.jpg';

function MainSlider() {

  let slider = useRef(null);

  const handleSelect = () => {
    TweenLite.to(slider, 0, {
      interval: 1000
    });
  };

  return (
    <div id="home" className="section">
      <div ref={el => (slider = el)} id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="1500">
        <ol className="carousel-indicators" >
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" onClick={handleSelect }></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"  onClick={handleSelect }></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"  onClick={handleSelect }></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" style={{ width: '100%' }} src={MainSliderimg} alt="First slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" style={{  width: '100%' }} src={SecandSliderimg} alt="Second slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" style={{  width: '100%' }} src={ThirdSliderimg} alt="Third slide" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainSlider;



