import React from 'react';
import emailjs from 'emailjs-com';
import Aos from 'aos';

class Contact extends React.Component {
    componentDidMount() {
        Aos.init({duration: 6000});
    }
    render() {
        const sendEmail = (e) => {
            e.preventDefault();

            emailjs.sendForm('service_ymt0eeb', 'template_iqz9d0f', e.target, 'user_c8qJWGomWMp5aGwiOVKDv')
                .then((result) => {
                    window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
                }, (error) => {
                    console.log(error.text);
                });
        }
        return <div data-aos="zoom-in-left" id="contact" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <h5><strong className="section-title">Contact Us</strong></h5>
                        <div id="map">

                        </div>
                    </div>
                    <div className="col-md-5">
                        <h5><strong className="section-title">Keep in Touch</strong></h5>
                        <form onSubmit={sendEmail}>
                            <div className="form-group">
                                <input className="form-control contact-input" type="email" name="from_email" placeholder="Email" required />
                            </div>
                            <div className="form-group">
                                <input className="form-control contact-input" name="subject" placeholder="Subject" required />
                            </div>
                            <div className="form-group">
                                <textarea className="form-controle contact-input" name="message" cols="30" rows="5" placeholder="Please Write Here" style={{ width: '-webkit-fill-available' }} required />
                            </div>
                            <div className="form-group">
                                <button className="contact-button form-control ayat-button">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    }
}
export default Contact;