import React from 'react';

class Header extends React.Component {
    
    render() {
        return <header  >
            <div className="fixed-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10">
                            <nav className="menu navbar">
                                <ul className="navbar-nav" style={{ display: "block" }} >
                                    <li className="nav-item ayat-nav-item">
                                        <a className="nav-link ayat-nav-link" href="#home"><span className="nav-text ayat-nav-text border-top">Home</span></a>
                                    </li>
                                    <li className="nav-item ayat-nav-item">
                                        <a className="nav-link ayat-nav-link" href="#about"><span className="nav-text ayat-nav-text">About</span></a>
                                    </li>
                                    <li className="nav-item ayat-nav-item">
                                        <a className="nav-link ayat-nav-link" href="#work"><span className="nav-text ayat-nav-text">Our Projects</span></a>
                                    </li>
                                    <li className="nav-item ayat-nav-item">
                                        <a className="nav-link ayat-nav-link" href="#team"><span className="nav-text ayat-nav-text">Team</span></a>
                                    </li>
                                    <li className="nav-item ayat-nav-item">
                                        <a className="nav-link ayat-nav-link" href="#technical"><span className="nav-text ayat-nav-text">Technical</span></a>
                                    </li>
                                    <li className="nav-item ayat-nav-item">
                                        <a className="nav-link ayat-nav-link" href="#contact"><span className="nav-text ayat-nav-text">Contact Us</span></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-2">
                            <div className="logo-menu">
                                <a href="#" className="logo"><img className="logo-image" src="/img/ic_ayat_media.svg"></img></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    }
}
export default Header;