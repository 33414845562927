import React from 'react';

function AboutItem(props) {
    if (props.id !== 2) {
        return <AboutSection title={props.title} content={props.content}></AboutSection>
    } else {
        return <ServiceSection title={props.title} content={props.content}></ServiceSection>
    }
}

function AboutSection(props) {
    return <div className="about-section-row">
        <div className="row about-section-title">
            <div className="col-md-12">
                <h1 style={{textAlign: 'initial'}}>{props.title}</h1>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <p style={{textAlign: 'initial',fontSize: '18px'}}>{props.content}</p>
            </div>
        </div>
    </div>
}


function ServiceSection(props) {
    const items = [
        { key : 1 ,  src: "img/ic_movies.svg", content: "2D & 3D movies (create the movie idea, Scenario script, Character Design, Movement, ..)" },
        {key : 2 , src: "img/ic_advertisement.svg", content: "Advertisements (TV & Social Media)" },
        { key : 3 ,src: "img/ic_marketing.svg", content: "Build Marketing plans for new products/companies." },
        {key : 4 , src: "img/ic_animation_movies.svg", content: "Animation movies." },
        { key : 5 ,src: "img/ic_training.svg", content: "Training courses" }
    ];
    return <div className="about-section-row">
        <div className="row about-section-title">
            <div className="col-md-12">
                <h1>{props.title}</h1>
            </div>
            <div className="row ">
                <div className="col-md-12 about-section-content">
                    <p style={{fontSize: '18px'}}>{props.content}</p>
                </div>
            </div>
        </div>
        <div className="row">

            {items.map(item => <ServiceItem key={item.key} src={item.src} content={item.content}></ServiceItem>)}

        </div>
    </div>
}


function ServiceItem(props) {
    return <div className="col-md-4">
        <img src={props.src} className="service-item-image"></img>
        <p className="service-item-content">{props.content}</p>
    </div>
}

export default AboutItem;