import React from 'react';
import './App.css';
import Header from './sections/header';
import Work from './sections/work';
import Technical from './sections/techniacl';
import Contact from './sections/contact';
import About from './sections/about';
import TeamComp from './sections/teamComp';
import MianSlider from './sections/mainSlider';
import Footer from './sections/footer';

class App extends React.Component {

  state = {
    height: window.innerHeight,
    width: window.innerWidth
  };
  render() {
    return (
      <div className="App">
        <Header></Header>
        <MianSlider />
        <About></About>
        <Work></Work>
        <TeamComp />
        <Technical></Technical>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    );
  }
}

export default App;
