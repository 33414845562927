import React from 'react';
import '../css/storyBord.scss';
import { ReactComponent as RightArrow } from '../img/ic_next.svg'


const InfoStory = (Props) => {
    const storyRow = Props.item;
    const fun = Props.hundlebordState;
    const innerImageLoaded = Props.innerImageLoaded;
    
    return (
        <div className="col-lg-4 col-sm-12 " id="outerBord" onClick={storyRow[3] ? () => fun(true, storyRow, false) : null} >
            <img className="img-fluid outer-img" id={'imgFluidOne' + storyRow[0]} onLoad={() => innerImageLoaded(storyRow[1][1])} alt="" src={storyRow[1][1]} />
            <a className="portfolio-box" >
                <div className="portfolio-box-caption">
                    {storyRow[3] ?
                        <div onClick={() => fun(true, storyRow, false)} className="btn-row btn-row-Right">
                            <RightArrow />
                        </div>
                        : ''}
                </div>
            </a>
        </div>
    );
}

export default InfoStory;





