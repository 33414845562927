import React from 'react';
import Aos from 'aos';

class Footer extends React.Component {
  componentDidMount() {
    Aos.init({duration: 3000});
}
  render() {
    return <div data-aos="fade-down" id="footer">
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="social-icons wow fadeInDown" >
                <ul>
                  <li className="facebook"><a href="https://www.facebook.com/Ayat-Workshop-112424860515060"><i className="fa fa-facebook"></i></a></li>
                  <li className="linkedin"><a href="https://www.linkedin.com/company/ayatgroup"><i className="fa fa-linkedin"></i></a></li>
                </ul>
              </div>
              <div className="site-info wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0.3s">
                <p>All copyrights reserved &copy; {new Date().getFullYear()} - Designed & Developed by <a rel="nofollow" href="#">Ayat Group</a></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>;
  }
}

export default Footer;