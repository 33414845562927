import React from 'react';
import Aos from 'aos';

class Technical extends React.Component {
    componentDidMount() {
        Aos.init({duration: 6000});
    }
    render() {
        const items = [
            { id: 1, src: "img/im_aftereffect.png", title: "Aftereffect" },
            { id: 3, src: "img/im_photoshop.png", title: "Photoshop" },
            { id: 4, src: "img/im_premiere.png", title: "Premiere" },
            { id: 2, src: "img/im_autodesk_Maya.png", title: "Autodesk_Maya" },
            { id: 5, src: "img/im_zbrush.png", title: "Zbrush" },
        ];
        return <div id="technical" data-aos="zoom-in-right" className="section">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <h5><strong className="section-technical-title">Technical</strong></h5>
                </div>
            </div>
            <div className="row technical-section-icon-row">
                <div className="col-md-1"></div>
                {items.map(item => <TechnicalItem key={item.id} src={item.src} title={item.title}></TechnicalItem>)}
                <div className="col-md-1"></div>
            </div>
        </div>
    }

}

function TechnicalItem(props) {
    return <div data-aos="fade-left" className="col-md-2 technical-item-image-col">
        <img src={props.src} className="technical-item-image"></img>
        <p className="technical-item-title">{props.title}</p>
        <div className="technical-item-image-div"></div>
    </div>
}


export default Technical;