import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Ic_cancel } from '../img/ic_cancel.svg'
import { InfoConsumer } from './context';
import InfoStory from './infoStory';
import '../css/storyBord.scss';
import gsap from 'gsap';
import withWidth from '@material-ui/core/withWidth';
import LoadingImg from '../img/loading.gif';
import { backGroundImg } from '../data';
import Aos from 'aos';

let tl = gsap.timeline();

const StoryBord = (Props) => {

    useEffect(() => {
        Aos.init({duration: 3000});
        
      }, []);

    const counter = useRef(0);
    const innerCounter = useRef(0);
    innerCounter.current = 0;
    counter.current = 0;
    const [bordState, setbordState] = useState(
        {
            switchStory: 0,
            innerStory: [],
            storyState: false,
            backgroundInner: '',
            loading: false
        });
    useEffect(() => {

        innerCounter.current = 0;
        counter.current = 0;

        setbordState({
            ...bordState,
            switchStory: Props.num,
            loading: false,
        });
        tl.to('.child-container', 0.1, {
            css: {
                width: '0',
            },
            ease: "expo.inOut",
        }).to('#Ic_cancel', 0.01, {
            css: {
                opacity: 0
            }
        }).to('#portfolio', 0, {
            css: {
                display: "block"
            }
        }).to('.btn-row-Right', 0.4, {
            css: {
                display: "block"
            }
        });
    }, [Props.num]);
    useEffect(() => {
        storyBordAnimation(bordState.storyState, bordState.backgroundInner);
    }, [bordState.storyState, bordState.innerStory, bordState.backgroundInner]);

    const hundlebordState = (param, currentStory, isLoading) => {
        if (counter.current === 5) {
            counter.current = 0;
        }
        let currentStory_m = '';
        let backgroundStory_m = '';
        if (currentStory) {
            currentStory_m = currentStory[1];
            backgroundStory_m = currentStory[3];
        }
        setbordState({
            ...bordState,
            storyState: param,
            innerStory: currentStory_m,
            backgroundInner: backgroundStory_m,
            loading: isLoading
        });

    };
    const LoadingFun = () => {

        setbordState({
            ...bordState,
            loading: true
        });

    };
    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= 4) {
            LoadingFun();
        }
    }
    const storyBordAnimation = (param, innerStory) => {
        var img = document.getElementById(innerStory);

        if (param) {
            tl.to('.btn-row-Right', 0.01, {
                css: {
                    display: 'none'
                }
            }).to('#Ic_cancel', 0.01, {
                css: {
                    opacity: 1
                }
            }).to('.bachdround-image', 0, {
                css: {
                    display: 'none',
                }
            }).to(img, 0, {
                css: {
                    display: 'block',
                }
            }).to('.child-container', 0.3, {
                css: {
                    width: '100%',
                },
                ease: "expo.inOut",
            });

        } else {
            tl.to('.child-container', 0.1, {
                css: {
                    width: '0',
                },
                ease: "expo.inOut",
            }).to('#Ic_cancel', 0.01, {
                css: {
                    opacity: 0
                }
            }).to('#portfolio', 0, {
                css: {
                    display: "block"
                }
            }).to('.btn-row-Right', 0.4, {
                css: {
                    display: "block"
                }
            });
        }

    }
    const innerImageLoaded = () => {
        innerCounter.current += 1;
        if (innerCounter.current >= 3) {
            LoadingFun();
        }
    }

    return (
        <div className="parent" data-aos="zoom-in-right">
            {/* <h2 id="StoryBoard-title">Story Board</h2> */}
            <div className="storyOne">
                <div id="portfolio">
                    <div className="container-fluid p-0" id='outerBord'>
                        <div id="LoadingImg" className="row no-gutters" style={{ display: !bordState.loading ? "block" : "none" }}>
                            <img src={LoadingImg} style={{ padding: '8%', color: '#96509a' }} />
                        </div>
                        <div className="row no-gutters" style={{ display: !bordState.loading ? "none" : "flex" }}>
                            <InfoConsumer>
                                {value => {
                                    console.log(value.storyImg[4]);
                                    return value.storyImg[bordState.switchStory].map(item => {
                                        return <InfoStory key={item[0]} item={item} hundlebordState={hundlebordState} loading={bordState.loading} innerImageLoaded={innerImageLoaded} />;
                                    });
                                }}
                            </InfoConsumer>
                        </div>
                    </div>
                    <div className=" child child-container" style={{ display: !bordState.loading ? "none" : "flex" }}>
                        {backGroundImg ?
                            backGroundImg.map((item, i) =>
                                <div className="child bachdround-image" style={{ background: `url(${item}) center center`,backgroundSize: 'cover' }} key={i} id={item}> </div>
                            ) : ''}
                        <div className=" child row child-content">
                            <div className="col-md-1"></div>
                            {bordState.innerStory ?
                                bordState.innerStory.map((item, i) =>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-8 col-10 img-child" id={'img-child'+i} key={i} >
                                        <a className="portfolio2-box">
                                            <img className="img-fluid hidden-image" onLoad={() => imageLoaded(item)} id="innerImg11" src={item} alt="" />
                                        </a>
                                    </div>
                                ) : ''}
                            <div onClick={() => hundlebordState(false, '', true)} id="Ic_cancel" className="btn-row btn-row-cancel col-xl-1 col-md-1">
                                <Ic_cancel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withWidth()(StoryBord);
