import React from 'react';
import Carousel from 'react-elastic-carousel';
import AboutItem from '../components/aboutItem';
import 'aos/dist/aos.css';
import Aos from 'aos';

class About extends React.Component {
    componentDidMount() {
        Aos.init({duration: 6000});
    }
    render() {
        
        const image = '../img/img_about.png';
        var parse = require('html-react-parser');
      
        const items = [
            { id: 1, image: '../img/img_about.png', title: 'Who We Are?' , content : parse("Ayat-Media is one of Ayat Group companies. <br> Specialized in short and long animation movies(2D-3D), Advertisements and Marketing in TV and Social Media. </br>") },
            { id: 2, image: '../img/img_about.png', title: 'Our Services' , content : parse("Ayat-Media main business is specialized in the following:") },
            { id: 3, image: '../img/img_about.png', title: 'Vision and Mission' , content : parse( "To introduce a new concept of advertising in the area (creativity, New advertising ideas) </br> To continue learning, developing and growing, that will enable us to achieve our regional professional </br> aspirations worldwide. </br> Ayat-Media selects partners and projects carefully to fulfill the real needs of such a promising projects, </br> and guarantee the continuous supply to public and private sectors. </br> Our experts and professional team focus on the opportunities that can bring the most benefit to all of </br> our partners.") }
        ];
        return <div id="about" data-aos="zoom-out-up" className="section">
            <div className="row">
                <div className="col-md-2 about-title-col">
                <h5 ><strong className="section-title">About us</strong></h5>
                </div>
                <div className="col-md-10 ">
                
                </div>
            </div>
            <div className="row about-item-row">
                <div className="col-md-3">
                    <img src={image} className="about-item-img"></img>
                </div>
                <div className="col-md-9 about-item-col" >
                    <Carousel itemsToShow={1} >
                        {items.map(item => <AboutItem key={item.id} content={item.content} id={item.id} title={item.title}></AboutItem>)}
                    </Carousel>
                </div>
            </div>
        </div>

    }
}
export default About;