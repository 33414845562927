import React from 'react';
import StoryBord from './storyBord';
import Aos from 'aos';

class Work extends React.Component {
    componentDidMount() {
        Aos.init({duration: 6000});
    }
    state = {
        switchStory: 0,
    };

    hundleswitchStory(item) {
        this.setState({ switchStory: item });
        console.log(item);
        item++;
        var video = document.getElementsByTagName('video')[0];
        video.poster = '../video/poster' + item + '.jpg';
        var sources = video.getElementsByTagName('source');
        sources[0].src = '../video/' + item + '.mp4';
        sources[1].src = '../video/' + item + '.ogg';
        sources[2].src = '../video/' + item + '.webm';
        video.load();
        var imgs = document.getElementsByClassName('story-board-tab-img');
        imgs[0].src = '../img/ic_slider_storyboard.svg';
        imgs[1].src = '../img/ic_slider_storyboard.svg';
        imgs[2].src = '../img/ic_slider_storyboard.svg';
        imgs[3].src = '../img/ic_slider_storyboard.svg';
        imgs[4].src = '../img/ic_slider_storyboard.svg';
        var img = document.getElementById('img-' + item);
        img.src = '../img/ic_slider_storyboard_active.svg';
        var played = document.getElementsByClassName("played");
 
        played[0].style.width = "0%";
    }

    render() {
        return <div id="work" className="section" data-aos="flip-left" style={{ marginBottom: '16%' }}>
            <div className="row our-works-row">
                <div className="col-md-4 " >
                    <h5>
                        <strong className="section-title">
                            Our Projects
                    </strong>
                    </h5>
                </div>
                <div className="col-md-2 story-board-tab-col">
                    <img id='img-1' className="story-board-tab-img" src="../img/ic_slider_storyboard_active.svg" onClick={() => this.hundleswitchStory(0)}></img>
                    <img id='img-2' className="story-board-tab-img" src="../img/ic_slider_storyboard.svg" onClick={() => this.hundleswitchStory(1)}></img>
                    <img id='img-3' className="story-board-tab-img" src="../img/ic_slider_storyboard.svg" onClick={() => this.hundleswitchStory(2)}></img>
                    <img id='img-4' className="story-board-tab-img" src="../img/ic_slider_storyboard.svg" onClick={() => this.hundleswitchStory(3)}></img>
                    <img id='img-5' className="story-board-tab-img" src="../img/ic_slider_storyboard.svg" onClick={() => this.hundleswitchStory(4)}></img>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">

                    <div className="player">
                        <video id="video" poster='../video/poster1.jpg'>
                            <source id="video1" src="../video/1.mp4" type="video/mp4" />
                            <source src="../video/1.ogg" type="video/ogg" />
                            <source src="../video/1.webm" type="video/webm" />
                              Sorry, your browser doesn't support HTML5 video playback.
                        </video>
                        <div className="controls" id="video-controls">
                            <button className="play paused" aria-label="Play">
                                <div className="play-icon">
                                    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid" width="20" height="10">
                                        <polygon fill="#fff" points="1,0 20,10 1,20" />
                                    </svg>
                                </div>
                                <div className="pause-icon">
                                    <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid" width="20" height="20">
                                        <rect fill="#fff" width="6" height="20" x="0" y="0" />
                                        <rect fill="#fff" width="6" height="20" x="12" y="0" />
                                    </svg>
                                </div>
                                <div className="replay-icon">
                                    <svg viewBox="-387 605 16 16" width="20" height="20">
                                        <path fill="#fff" d="M-387 606v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1s-.4-1-1-1h-1.5c1.1-1.2 2.7-2 4.5-2 3.3 0 6 2.7 6 6s-2.7 6-6 6c-2.3 0-4.4-1.3-5.4-3.4-.2-.5-.8-.7-1.3-.5-.5.2-.7.8-.5 1.3 1.3 2.8 4.2 4.6 7.2 4.6 4.4 0 8-3.6 8-8s-3.6-8-8-8c-2.3 0-4.5 1-6 2.7V606c0-.6-.4-1-1-1s-1 .4-1 1z"></path>
                                    </svg>
                                </div>
                            </button>
                            <div className="play-bar">
                                <div className="progress">
                                    <div className="loaded" role="progressbar" aria-label="loaded"></div>
                                    <div className="played" role="progressbar" aria-label="played"></div>
                                    <div className="ghost-timecode" role="presentation" aria-hidden="true">
                                        <span></span>
                                    </div>
                                    <div className="timecode" role="presentation" aria-hidden="true">
                                        <span></span>
                                    </div>
                                </div>
                                <div className="volume" role="slider" aria-label="Volume" aria-valuemin="0" aria-valuemax="1" tabIndex="0">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <button className="fullscreen" title="Toggle full screen" aria-label="Fullscreen">
                                    <div className="enter-fullscreen">
                                        <svg preserveAspectRatio="xMidYMid" tabIndex="-1" width="20" height="20">
                                            <polyline className="fills" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9" transform="translate(6,6)"></polyline>
                                            <polyline className="fills" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9" transform="translate(6,6) rotate(90)"></polyline>
                                            <polyline className="fills" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9" transform="translate(6,6) rotate(180)"></polyline>
                                            <polyline className="fills" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9" transform="translate(6,6) rotate(270)"></polyline>
                                        </svg>
                                    </div>
                                    <div className="exit-fullscreen">
                                        <svg preserveAspectRatio="xMidYMid" tabIndex="-1" width="20" height="20">
                                            <polyline className="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1" transform="translate(6,6) "></polyline>
                                            <polyline className="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1" transform="translate(6,6) rotate(90)"></polyline>
                                            <polyline className="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1" transform="translate(6,6) rotate(180)"></polyline>
                                            <polyline className="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1" transform="translate(6,6) rotate(270)"></polyline>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StoryBord num={this.state.switchStory} />
        </div>

    }
}
export default Work;